import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

import text from "../text-data.json";
const BannerOne = () => {
  return (
    <>
      {/* ================== BannerOne Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover"
        // style={{ backgroundImage: 'url("./assets/img/banner/0.png")' }}
        id="home"
      >
        {/* <img
          className="animate-img-1 top_image_bounce"
          src="assets/img/banner/2.png"
          alt="img"
        />
        <img
          className="animate-img-2 left_image_bounce"
          src="assets/img/banner/5.svg"
          alt="img"
        /> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h6
                  className="subtitle"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  {text.landingPage.heroSection.headline}
                </h6>
                <h2
                  className="title"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  Making Your <span>Solutions </span>Alive
                </h2>
                <p
                  className="content pe-xl-5"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                  {text.landingPage.heroSection.subheadline}
                </p>
                <a
                  className="btn btn-border-base"
                  data-aos="fade-right"
                  data-aos-delay="300"
                  data-aos-duration="1500"
                  href="#about"
                >
                  Discover More <FaPlus />
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-12">
              <img
                className="banner-animate-img top_image_bounce"
                src="assets/img/banner/splash.svg"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default BannerOne;
