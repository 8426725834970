import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
    console.log("hell");
  };

  // Control sidebar navigation
  document.querySelectorAll(".menu-item-has-children > a").forEach(item => {
    item.addEventListener('click', () => {
      item.parentElement.querySelector(".sub-menu").classList.toggle("active");
      item.classList.toggle("open");
      document.querySelector('.navbar-area.nav-container').classList.toggle("border-less");
    });
  });

  return (
    <>
      {/* navbar start */}
      <nav className="navbar navbar-area navbar-expand-lg">
        <div className={
          active 
          ? "container nav-container navbar-bg border-less"
          : "container nav-container navbar-bg"
          }>
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <Link to="/">
              <img src="assets/img/logo.svg" alt="img" />
            </Link>
          </div>
          <div className="logo-mobile">
            <Link to="/">
              <img src="assets/img/logo-short.svg" alt="img" />
            </Link>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li className="menu-item-has-children">
                <a href="#">Home</a>
              </li>
              <li className="menu-item-has-children">
                <a href="#about-us">About us</a>
              </li>
              <li className="menu-item-has-children">
                <a href="#process">Process</a>
              </li>
              <li className="menu-item-has-children">
                <a href="#offer">Offer</a>
              </li>
              <li>
                <a href="#contact">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* navbar end */}
    </>
  );
};

export default NavBar;
