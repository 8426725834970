import React from "react";
import AboutAreaFour from "../components/AboutAreaFour";
import BannerOne from "../components/BannerOne";
import Footer from "../components/FooterFive";
import NavBar from "../components/NavBarFive";
import WorkProcessOne from "../components/WorkProcessOne";
import ServiceAreaFour from "../components/ServiceAreaFour";
import ContactAreaFour from "../components/ContactAreaFour";

const HomeOne = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Banner One */}
      <BannerOne />

      {/* About Area Four */}
      <AboutAreaFour />

      {/* Work Process One */}
      <WorkProcessOne />
      {/* Service Area One */}
      <ServiceAreaFour />

      {/* Counter Area One */}
      <ContactAreaFour />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default HomeOne;
