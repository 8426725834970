import React from "react";
import {
  FaArrowRight,
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className="footer-area flex-center padding-tb-25">
        <img className="footer-logo" src="assets/img/logo-short.svg" alt="img" />
        <p className="footer-copyright">© Digital Turquoise {new Date().getFullYear()} | All Rights Reserved</p>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default Footer;
