import React from "react";
import text from "../text-data.json";

const WorkProcessOne = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className="work-process-area pd-bottom-90" id="process">
        <img
          className="banner-animate-img pd-bottom-80 align-img-center"
          src="assets/img/work-process/1.svg"
          alt="img"
        />
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">OUR SIMPLE PROCESS</h6>
            <h2 className="title">
              Our streamlined process means you can have engineers working on
              your project within <span>1-2 weeks</span>.
            </h2>
          </div>
          <div className="row">
            {text.landingPage.ourProcess.steps.map(
              ({ type, describtion }, index) => {
                return (
                  <div className="col-xl-3 col-md-6">
                    <div className="single-work-process-inner">
                      <div className="thumb mb-3">
                        <img
                          src={`assets/img/numbers/number-${index + 1}.png`}
                          alt="img"
                        />
                      </div>
                      <div className="details" style={{ height: "220px" }}>
                        <p className="process-count"></p>
                        <h5 className="mb-3">{type}</h5>
                        <p className="content">{describtion}</p>
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
