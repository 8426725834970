import React from "react";

// Dynamically import all .png files from the specified folder
const images = require.context('../../public/assets/img/tech-services', false, /\.png$/);

// Create an object mapping filenames to image paths
const imageMap = images.keys().reduce((acc, path) => {
  const name = path.replace('./', '').replace('.png', ''); // Remove './' and '.png'
  
  // Only add to `imageMap` if the name does not start with a number
  if (!/^[0-9]/.test(name)) {
    acc[name] = images(path);
  }
  return acc;
}, {});

const ServiceAreaFour = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div
        className="service-area bg-cover pd-bottom-90"
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
        id="offer"
      >
        <img
          className="banner-animate-img pd-bottom-80 align-img-center"
          src="assets/img/service/16.svg"
          alt="img"
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title text-center">
                <h6 className="sub-title">WHAT WE OFFER</h6>
                <h2 className="title">
                  <span>Talented Engineers</span> in different fields of
                  specialities
                </h2>
              </div>
            </div>
          </div>
          <div className="our-service-list">
            {Object.keys(imageMap).map((key) => (
              <img src={imageMap[key]} alt={key} className={key.includes('Java') ? "vertical-img" : "img-fluid"} />
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaFour;
