import React from "react";
import text from "../text-data.json";

const AboutAreaFour = () => {
  return (
    <>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area pd-top-120 pd-bottom-110" id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner p-xl-5 pt-4 "
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <img
                  className="animate-main-img"
                  src="assets/img/about/31.svg"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">{text.landingPage.aboutUs.usp}</h2>
                <p className="content mb-4 mb-xl-5">
                  {text.landingPage.aboutUs.intro}
                </p>

                <div className="media box-shadow p-3 border-radius-5 mb-3 border-l-5-dt">
                  <div className="media-body">
                    <h6>{text.landingPage.aboutUs.mission}</h6>
                    <div className="skill"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </>
  );
};

export default AboutAreaFour;
